import React from 'react';

import { BasePage } from '../components/BasePage';
import { ContentCard } from '../components/ContentCard';

// tslint:disable-next-line:no-var-requires
require("./index.less");

interface HomePageProps extends React.HTMLProps<HTMLDivElement> { }
interface HomePageState extends React.HTMLProps<HTMLDivElement> {
    seed: number,
}

import appMlp from '../images/apps/mlp.png' 
import appBog from '../images/apps/bog.png' 

export const AllApps = [
    <a href="https://mylittleputin.com" > <img src={appMlp} /> My Little Putin </a>,
    <a href="https://bog.computer" ><img src={appBog} /> bog.computer </a>
]

export const GalleryApps = (apps = AllApps) => <div key={"_GALLERY_APPS"} className='appsContainer' children={apps} /> 

export default class HomePage extends React.Component<HomePageProps, HomePageState> {

    constructor(props) {
        super(props);
        this.state = {seed: Date.now()}
    }


    _key = 0
    get key () {
        return "INDEXKEY" + (this._key++).toString()
    }
    
    public render() {
        return <BasePage key="Main Page" children={[
            <ContentCard key={this.key} style={{textAlign:"center"}} children={[
                {type: "Heading1", content: "Gallery"},
                GalleryApps()
            ]} />
        ]} />
    }
}